import axios from "axios"
import { getCookieValue } from "@/views/common"

export const BASE_URL = "/integrations/services"

export const instance = axios.create({
  baseURL: BASE_URL,
  timeout: 60000,
  responseType: "json",
})

instance.interceptors.request.use((config) => {
  config.headers["X-CSRFToken"] = getCookieValue("csrftoken")
  return config
})
